"use client";

import { checkIfUserIsOnWaitlist } from "@/components/modal/waitlistFormModal/serverActions";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { event, reinitialize } from "@/lib/fpixel";
import { handleError } from "@/lib/apiUtils";
import { useRouter } from "next/navigation";

const useLandingPageInvite = () => {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const posthog = usePostHog();
  const router = useRouter();
  const joinWaitlist = async (emailAddress: string) => {
    try {
      setLoading(true);
      const response = await fetch("/api/user/join_waitlist", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email_address: emailAddress }),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        reinitialize(result.user_id);
        event("user_signup_waitlist", {
          status: "success",
          em: emailAddress,
        });
        posthog.identify(emailAddress);
        router.push("/onboarding?webview=true");
      } else {
        throw result.error || new Error("Unknown error occurred");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    (async () => {
      const isOnWaitlist = await checkIfUserIsOnWaitlist();
      if (isOnWaitlist) {
        setSubmitted(true);
      }
    })();
  }, []);

  return {
    submitted,
    loading,
    joinWaitlist,
  };
};

export default useLandingPageInvite;
