"use client";

import { checkWebview } from "@/lib/utils";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { theme } from "@/themes";
import { Session } from "next-auth";

type MainLayoutContextType = {
  renderMiddleContent?: JSX.Element;
  setRenderMiddleContent?: Dispatch<SetStateAction<JSX.Element | undefined>>;
  logoOnlyInheader?: boolean;
  setLogoOnlyInHeader?: Dispatch<SetStateAction<boolean>>;
  initialLoading?: boolean;
  isWebView?: boolean;
  isWebViewLoaded?: boolean;
};

const MainLayoutContext = createContext<MainLayoutContextType>({
  initialLoading: true,
});

export default function MainLayoutProvider({
  children,
  session,
}: PropsWithChildren<{ session: Session | null }>) {
  const [renderMiddleContent, setRenderMiddleContent] = useState<JSX.Element>();
  const [isWebView, setIsWebView] = useState(true); // Default to true until we know otherwise
  const [isWebViewLoaded, setIsWebViewLoaded] = useState(false);
  const [logoOnlyInheader, setLogoOnlyInHeader] = useState(false);

  useEffect(() => {
    setIsWebView(checkWebview());
    setIsWebViewLoaded(true);
  }, [session?.user.id]);

  return (
    <MainLayoutContext.Provider
      value={{
        renderMiddleContent,
        setRenderMiddleContent,
        isWebView,
        isWebViewLoaded,
        logoOnlyInheader,
        setLogoOnlyInHeader,
      }}
    >
      <ProgressBar
        height="3px"
        color={theme.extend.colors["primary-gold"]}
        options={{ showSpinner: false }}
        shallowRouting
      />
      {children}
    </MainLayoutContext.Provider>
  );
}

export const useMainLayout = () => {
  const {
    renderMiddleContent,
    setRenderMiddleContent,
    isWebView,
    isWebViewLoaded,
    logoOnlyInheader,
    setLogoOnlyInHeader,
  } = useContext(MainLayoutContext);

  return {
    renderMiddleContent,
    setRenderMiddleContent,
    isWebView,
    isWebViewLoaded,
    logoOnlyInheader,
    setLogoOnlyInHeader,
  };
};
